import { template as template_eb34678126914581b18d0bca9a703d12 } from "@ember/template-compiler";
const FKLabel = template_eb34678126914581b18d0bca9a703d12(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_2a8061a0e75a4efab920df66cd860ff3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2a8061a0e75a4efab920df66cd860ff3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_444ae09d854e4d8e941be613b1971b0b } from "@ember/template-compiler";
const SidebarSectionMessage = template_444ae09d854e4d8e941be613b1971b0b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_004198590b7a4feaa1ba7e1404bf71ef } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_004198590b7a4feaa1ba7e1404bf71ef(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
